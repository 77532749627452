import React from 'react';

const GoogleMap = () => {
  const mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.2348648195907!2d79.9644235!3d6.862432!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25100629b79f7%3A0xeaf59daa5a58d6b7!2sAdvanced%20Aluminium%20(Pvt)%20Ltd!5e0!3m2!1sen!2slk!4v1708187755301!5m2!1sen!2slk";

  return (
    <div className="w-full">
      <iframe
        src={mapSrc}
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        title="Google Map"
      ></iframe>
    </div>
  );
};

export default GoogleMap;
