import React from 'react';
// import '../../../public/asset/services/projectOne.png'
const sampleProjects = [
    {
      title: 'High Quality Aluminium Doors & Windows',
      description: 'Durable and sleek, enhancing homes with their elegant, energy-efficient designs.',
      image: '/asset/home/1.png'
    },
    {
      title: 'Tempered Glass Facades',
      description: 'Shimmering glass exteriors providing modern aesthetics and structural integrity to buildings.',
      image: '/asset/home/2.png'
    },
    {
      title: 'Office Partitions',
      description: 'Versatile and stylish partitions offering privacy without sacrificing an open-office feel.',
      image: '/asset/home/3.png'
    },
    {
      title: 'Glass, Aluminium & Iron Balustrades/Handrails',
      description: 'Combining functionality with elegance for staircases and balconies.',
      image: '/asset/home/4.png'
    },
    {
      title: 'Skylights',
      description: 'Transforming ceilings into canvases of sky, flooding interiors with natural light.',
      image: '/asset/home/5.png'

    },
    {
        title: 'Tempered Glass Canopies',
        description: 'Providing shelter while maintaining transparency for outdoor elegance.',
        image: '/asset/home/6.png'
      },
      {
        title: 'Shower Cubicles/Shower Panels/Shower Enclosures',
        description: 'Contemporary and minimalist shower designs that redefine bathroom luxury',
        image: '/asset/home/7.png'
      },
      {
        title: 'High Quality Aluminium Pantry Cupboards',
        description: 'Modern storage solutions that blend durability with design',
        image: '/asset/home/8.png'
      },
      {
        title: 'Tempered Glass Backsplashes',
        description: 'Easy-to-clean, stylish backsplashes that protect and adorn kitchen walls.',
        image: '/asset/home/9.png'
      },
      {
        title: 'ACP Cladding Facades',
        description: 'Futuristic and eco-friendly facades that offer versatility in design and color for urban structures.',
        image: '/asset/home/10.png'
      }
 
  ];
  

const Services = () => {
  return (
    <div className="bg-white p-4 md:p-8" style={{ userSelect: 'none' }}>
      <h2 className="text-xl font-bold text-center mb-8">Innovative Aluminum Solutions</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4">
        {sampleProjects.map((service, index) => (
          <div key={index} className="service-card">
            <img src={service.image} alt={service.title} className="service-card-image" />
            <div className="service-card-overlay">
              <h3 className="service-card-title">{service.title}</h3>
              <p className="service-card-description">{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;

// Styles (consider moving to a CSS file)
const style = `
  .service-card {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .service-card-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .service-card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .service-card:hover .service-card-overlay {
    opacity: 1;
  }

  .service-card-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .service-card-description {
    font-size: 0.9rem;
    line-height: 1.4;
  }
`;

document.head.appendChild(document.createElement("style")).textContent = style;
