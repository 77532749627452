import React from 'react';
import '../../styles/About.css'; // Only for custom animation

const AboutUs = () => {
  return (
    <div className="text-center p-5">
      <h2 className="text-2xl font-bold mb-4 animate-fadeInUp">About Us</h2>
      <p>
        We are a dynamic and innovative company specializing in aluminum solutions.
        Our mission is to deliver high-quality products and services that exceed our 
        customers' expectations. With cutting-edge technology and a dedicated team, 
        we're shaping the future of the industry.
      </p>
    </div>
  );
};

export default AboutUs;
