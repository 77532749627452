import React from 'react';
import { FaMapMarkerAlt, FaPhone, FaEnvelope, FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';

const ContactInfo = () => {
  return (
    <div className="bg-white p-5 md:p-10">
      <h2 className="text-2xl font-bold text-center mb-6">Contact Information</h2>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        
        {/* Address Section */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h3 className="font-bold text-lg mb-2">
            <FaMapMarkerAlt className="inline mr-2" /> Address
          </h3>
          <p>1164/B,</p>
          <p> Malabe Road,Kottawa,</p>
          <p> Sri Lanka</p>
        </div>

        {/* Telephone & Email Section */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <h3 className="font-bold text-lg mb-2">
            <FaPhone className="inline mr-2" /> Contact
          </h3>
          <p> 077 738 2259</p>
          <p> 076 396 5557</p>
          <p><FaEnvelope className="inline mr-1" />Email: info@adal.lk</p>
        </div>

        {/* Social Media Section */}
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
        <h3 className="font-bold text-lg mb-2">Follow Us</h3>
        <div className="flex justify-center md:justify-start gap-2">
          <a href="https://www.facebook.com/profile.php?id=61552687340293" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          {/* Add other social media links in a similar way */}
          {/* <FaTwitter />
          <FaLinkedin /> */}
        </div>
      </div>
      </div>
    </div>
  );
};

export default ContactInfo;
