import React from 'react';

// Import logos - replace these with actual paths or imports
const logoKinLong = '../../asset/SerProvider/1.png'; 
const logoAlumex = '../../asset/SerProvider/2.png';
const logoSaintGobain = '../../asset/SerProvider/3.png';
const logoAntalya = '../../asset/SerProvider/4.png';
const logoBaiYui = '../../asset/SerProvider/5.png';

const ServicesProviders = () => {
  const providers = [
    { name: 'Kin Long', logo: logoKinLong },
    { name: 'Alumex', logo: logoAlumex },
    { name: 'Saint-Gobain', logo: logoSaintGobain },
    { name: 'Antalya', logo: logoAntalya },
    { name: 'Bai Yui', logo: logoBaiYui },
  ];

  return (
    <div className="bg-white p-5 md:p-10">
      <h2 className="text-2xl font-bold text-center mb-6">Our Trusted Service Providers</h2>
      <div className="max-w-6xl mx-auto grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-8 text-center">
        {providers.map((provider, index) => (
          <div key={index} className="flex flex-col items-center">
            <img src={provider.logo} alt={provider.name} className="h-20 mb-2" />
            <h3 className="text-lg font-semibold">{provider.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesProviders;
