import React, { useState, useEffect } from 'react';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="relative w-full h-auto md:h-[600px]"> <img src="../../../asset/services/head_01.png" alt="About Us" className="w-full h-full object-cover" />
    {/* Logo - Only show when not scrolled */}
    {!isScrolled && (
      <div className="absolute top-3 left-3 md:top-5 md:left-6 z-30">
        <img src="../../../asset/light-logo.png" alt="Company Logo" className="h-16 md:h-28" />
      </div>
    )}
  </header>
  );
};

export default Header;
