import React from 'react';
import { FaPhone, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa'; 

const Footer = () => {
  const partnerImages = [
    "../../asset/SerProvider/1.png",
    "../../asset/SerProvider/2.png",
    "../../asset/SerProvider/3.png",
    "../../asset/SerProvider/4.png",
    "../../asset/SerProvider/5.png"

  ];




  return (
    <>
    <style>
    {`
      .logo-popup {
        position: relative;
        display: inline-block;
      }

      .logo-popup img {
        display: none;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        width: 100px; /* Adjust size as needed */
        transition: 0.3s ease;
        border: 1px solid #ddd; /* Optional: for styling */
        background-color: white;
        padding: 5px;
        box-shadow: 0px 2px 8px rgba(0,0,0,0.2);
      }

      .logo-popup:hover img {
        display: block;
      }
    `}
  </style>



  <footer className="bg-gray-800 text-white pt-2 pb-4 px-4">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-4">
        
        {/* Partners Section */}
        <div>
          <h3 className="font-bold mb-2">Our Partners</h3>
          <div className="grid grid-cols-2 gap-1">
            {partnerImages.map((image, index) => (
              <img key={index} src={image} alt={`Partner ${index + 1}`} className="object-cover h-8 w-24" />
            ))}
          </div>
        </div>

        {/* Services Section */}
       {/* Services Section */}
       <div>
          <h3 className="font-bold mb-2">Our Services</h3>
          <ul className="list-disc pl-5 text-xs">
            <li><a href="/Services" className="text-white hover:text-gray-300">High quality aluminium doors & windows</a></li>
            <li><a href="/Services" className="text-white hover:text-gray-300">Tempered glass facades</a></li>
            <li><a href="/Services" className="text-white hover:text-gray-300">Office partitions</a></li>
            <li><a href="/Services" className="text-white hover:text-gray-300">Glass, aluminium & iron balustrades/handrails</a></li>
            <li><a href="/Services" className="text-white hover:text-gray-300">Skylights</a></li>
            <li><a href="/Services" className="text-white hover:text-gray-300">Tempered glass canopies</a></li>
            <li><a href="/Services" className="text-white hover:text-gray-300">Shower cubicles/shower panels/shower enclosures</a></li>
            <li><a href="/Services" className="text-white hover:text-gray-300">High quality aluminium pantry cupboards</a></li>
            <li><a href="/Services" className="text-white hover:text-gray-300">Tempered glass backsplashes</a></li>
            <li><a href="/Services" className="text-white hover:text-gray-300">ACP cladding facades</a></li>
          </ul>
        </div>


        {/* Contact Details Section */}
        <div>
          <h3 className="font-bold mb-2">Contact Us</h3>
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <FaPhone />
              <span>0777382259</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaPhone />
              <span>0763965557</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaMapMarkerAlt />
              <span>1164/B, Malabe Road, Kottawa</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaEnvelope />
              <span>Email: info@adal.lk</span>
            </div>
          </div>
        </div>

        {/* Copyright and Credits Section */}
        <div>
          <p>&copy; {new Date().getFullYear()} Advanced Aluminium Pvt Ltd</p>
          <div>
            <a href="https://www.ceylonsprint.com" target="_blank" rel="noopener noreferrer" className="logo-popup text-xs">
              Designed and Developed by Ceylon Sprint - Software Solution
              <img src="../../asset/CS/ceylonsprint.png" alt="Ceylon Sprint Logo" />
            </a>
          </div>
        </div>


      </div>
    </footer>
    </>
  );
};

export default Footer;
