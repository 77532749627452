import React from 'react';
import MainLayout from '../layouts/ServiceLayout';
import ServicesPage from '../components/services/ServicesPage';
// import AboutUs from '../components/about/AboutUs';
// import VisionAndMission from '../components/about/VisionAndMission';
// import ServicesProviders from '../components/about/ServicesProviders';
// import Services from '../components/home/project';
// import CompanyOverview from '../components/home/BriefAbout';

const Services = () => {
 
  return (
    <MainLayout>
      <ServicesPage />
        {/* <AboutUs />
        <VisionAndMission />
        <ServicesProviders />
      {/* <Services />
      <CompanyOverview /> */} 

    </MainLayout>
  );
};

export default Services;