import React from 'react';
import AB from '../../asset/homeAb.png';
const CompanyOverview = () => {
  return (
    <div className="w-full  bg-cover bg-center flex items-center justify-center mb-8" style={{ backgroundImage: "url('/path/to/your/background.jpg')" }}>
      <div className="text-center text-black p-4">
        <img src={AB} alt="Company Logo" className="mx-auto h-24 mb-4" />
        <h1 className="text-3xl font-bold mb-2">ADAL: Versatile Aluminium Solutions for Every Sector</h1>
        <p className="text-lg text-justify">
        At ADAL, we turn visions into reality. Your space deserves the best, and our expert team is committed to delivering impeccable design, flawless installation, and lasting transformations. From doors and windows that connect you with the outside world to baths and kitchens that blend functionality with creativity, we ensure every corner of your home reflects elegance and comfort. Our focus extends to collaborating with architects and construction professionals, ensuring every design need is met with precision and innovation. With ADAL, experience a home that's not just built, but thoughtfully crafted to inspire and rejuvenate."

This version retains the core message but is more succinct, making it suitable for a homepage where brevity and impact are key
        </p>
      </div>
    </div>
  );
};

export default CompanyOverview;
