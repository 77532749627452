import React from 'react';
import MainLayout from '../layouts/evnPolicyLayout';
import EnvironmentalInfo from '../components/envPolicy/EnvironmentalInfo';
// import ContactInfo from '../components/contact/ContactInfo';
// import GoogleMap from '../components/contact/GoogleMap';
// import AboutUs from '../components/about/AboutUs';
// import VisionAndMission from '../components/about/VisionAndMission';
// import ServicesProviders from '../components/about/ServicesProviders';
// import Services from '../components/home/project';
// import CompanyOverview from '../components/home/BriefAbout';

const EnvPolicy = () => {
 
  return (
    <MainLayout>
        <EnvironmentalInfo />
      {/* <ContactInfo />
      <GoogleMap /> */}
    </MainLayout>
  );
};

export default EnvPolicy;