import React from 'react';
import MainLayout from '../layouts/AboutLayout';
import AboutUs from '../components/about/AboutUs';
import VisionAndMission from '../components/about/VisionAndMission';
import ServicesProviders from '../components/about/ServicesProviders';
// import Services from '../components/home/project';
// import CompanyOverview from '../components/home/BriefAbout';

const About = () => {
 
  return (
    <MainLayout>
        <AboutUs />
        <VisionAndMission />
        <ServicesProviders />
      {/* <Services />
      <CompanyOverview /> */}

    </MainLayout>
  );
};

export default About;