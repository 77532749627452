import React from 'react';

const EnvironmentalInfo = () => {
  return (
<>
<style>
{`
          @keyframes rotate-inverse {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(-360deg);
            }
          }

          .rotate-animation-inverse {
            animation: rotate-inverse 10s linear infinite;
          }
        `}
      </style>


    <div className="px-4 py-6 md:px-8 lg:px-12 bg-white text-gray-800">
      {/* Environmental Policy Statement */}
      <div className="mb-6">
        <h2 className="text-2xl md:text-3xl font-semibold mb-3">Environmental Policy Statement</h2>
        <p className="text-base md:text-lg">Advanced Aluminium Pvt Ltd (ADAL) is an environmentally conscious business. As industry leaders, ADAL conducts its business responsibly and sustainably. We are committed to preventing pollution and continually improving our environmental practices. This policy is subject to change at ADAL's discretion.</p>
      </div>

      {/* Environmental Objectives */}
      <div className="mb-6">
        <h2 className="text-2xl md:text-3xl font-semibold mb-3">Environmental Objectives</h2>
        <ul className="list-disc pl-5 text-base md:text-lg">
          <li>Ensuring environmental protection and pollution prevention.</li>
          <li>Treating environmental concerns equally with other issues and continuously improving our activities.</li>
          <li>Raising standards through examining current actions, employee involvement, and stakeholder communication.</li>
          <li>Establishing environmentally respectful work procedures and training staff to follow them.</li>
          <li>Accountability for environmental issues at the managerial and supervisory levels.</li>
        </ul>
      </div>

      {/* Waste Management Practices */}
      <div>
        <h2 className="text-2xl md:text-3xl font-semibold mb-3">Waste Management Practices</h2>
        <ul className="list-disc pl-5 text-base md:text-lg">
          <li>Recycling glass waste for use in insulation, bottles, and construction.</li>
          <li>Converting edge working machines and glass wash systems to recycle water.</li>
          <li>Recycling waste timber for use in playgrounds and garden mulch.</li>
          <li>Recycling aluminium waste from our installation teams and manufacturing.</li>
          <li>Sending steel container frames for metal recycling.</li>
          <li>Separating and recycling paper, plastics, and polystyrene through “5R” Recyclers.</li>
        </ul>
      </div>
 {/* Images related to Waste Management Practices */}
   <div className="flex flex-wrap justify-center">
        <img src="../../../asset/policy/1.png" alt="Waste Management Image 1" className="m-2 w-full md:w-1/2 lg:w-1/3 object-cover"/>
        <img src="../../../asset/policy/2.png"  alt="Waste Management Image 2" 
             className="m-2 w-full md:w-1/2 lg:w-1/3 object-cover rotate-animation-inverse"/>
      </div>
    </div>
    </>
  );
};

export default EnvironmentalInfo;
