import React, { useState, useEffect } from 'react';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className="relative w-full h-auto md:h-[600px]">
      <img src="../../../asset/contact/1.png" alt="About Us" className="w-full h-full object-cover" />

      {/* Content Overlay */}
      <div className="absolute top-1/2 left-4 md:left-10 transform -translate-y-1/2 z-20">
  <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-black">CONTACT US</h1>
  <div className="w-full md:w-3/4 lg:w-1/2 text-sm md:text-md lg:text-lg font-bold text-black text-left">
    <p>
      Connect with ADVANCED ALUMINIUM PVT LTD: Your gateway to innovative and sustainable aluminum solutions. We're here to assist with your needs and inquiries.
    </p>
  </div>
</div>
      {/* Logo - Only show when not scrolled */}
      {!isScrolled && (
        <div className="absolute top-3 left-3 md:top-5 md:left-6 z-30">
          <img src="../../../asset/light-logo.png" alt="Company Logo" className="h-16 md:h-28" />
        </div>
      )}
    </header>
  );
};

export default Header;
