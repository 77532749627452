import React, { useState } from 'react';
import '../../styles/Services.css'; // Make sure to create this CSS file

const ServicesPage = () => {
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [showNoSubcategoriesMessage, setShowNoSubcategoriesMessage] = useState({});

  const toggleCategoryExpansion = (categoryId) => {
    if (expandedCategories.includes(categoryId)) {
      setExpandedCategories(expandedCategories.filter(id => id !== categoryId));
    } else {
      setExpandedCategories([...expandedCategories, categoryId]);
    }
    setShowNoSubcategoriesMessage({ ...showNoSubcategoriesMessage, [categoryId]: false });
  };

  const mainCategories = [
    {
      id: 1,
      title: "High Quality Aluminium Doors & Windows",
      image: "../../../asset/services/Services/1.png",
      description: "Durable and sleek, these aluminium doors and windows offer superior strength and longevity. Designed to enhance aesthetic appeal, they provide excellent insulation and are low maintenance, making them a popular choice for modern architecture.",
      subcategories: [
        { id: 1, title: "Sliding Windows", image: "../../../asset/services/subcoregoty/1.png", description: "Sliding windows feature a sleek design where the window pane slides horizontally along tracks. Ideal for tight spaces, they offer ease of operation and provide excellent ventilation and unobstructed views, making them a popular choice in modern architecture." },
        { id: 2, title: "Sliding & Folding Window", image: "../../../asset/services/subcoregoty/2.png", description: "These windows combine the features of sliding windows with the flexibility of folding ones. They can slide and fold back, opening up an area completely. This design is perfect for maximizing space and integrating indoor and outdoor environments." },
        { id: 3, title: "Casement (Swing) Window", image: "../../../asset/services/subcoregoty/3.png", description: "Casement windows, or swing windows, are hinged at the sides and open outward like a door. They provide excellent ventilation, a clear view, and are known for their airtight closure, making them energy efficient and secure." },
        { id: 4, title: "Tilt N Turn Window", image: "../../../asset/services/subcoregoty/4.png", description: "Tilt N Turn windows offer versatile functionality, opening both inwards from the top (tilt) for ventilation and swinging inwards from the side (turn) for easy cleaning and egress. These windows are popular for their flexibility and modern aesthetic." },
        { id: 5, title: "Louver", image: "../../../asset/services/subcoregoty/5.png", description: "Louver windows consist of horizontal slats that can be adjusted to control airflow and sunlight while maintaining privacy and protection from the elements. They are ideal for areas requiring adjustable ventilation, like bathrooms and kitchens." },
        { id: 6, title: "Office Partitions", image: "../../../asset/services/subcoregoty/6.png", description: "Office partitions are used to efficiently divide workspace areas. They can be made from various materials, including glass, which offers a balance between private and collaborative workspaces while maintaining an open and light environment." },
        { id: 7, title: "C Grove 100mm swing door & 100 mm Sliding door", image:"../../../asset/services/subcoregoty/7.png", description: "The C Grove series features both swing and sliding doors with a 100mm profile. These doors are designed for durability and style, fitting seamlessly into modern interior designs. The swing doors offer traditional functionality, while the sliding doors save space, making them ideal for various architectural needs." }
      ]
    },
    {
      id: 2,
      title: "Tempered Glass Facades",
      image: "../../../asset/services/Services/2.png",
      description: "These facades feature strengthened tempered glass, offering a contemporary and sophisticated look to buildings. They are highly resistant to impact and temperature changes, ensuring safety while maximizing natural light and visibility.",
      subcategories: [
        // { id: 8, title: "Single Glazing Facades", image: "../../../asset/slid/2.jpg", description: "Discover our single glazing facade options." },
        // { id: 9, title: "Double Glazing Facades", image: "../../../asset/slid/2.jpg", description: "Explore our double glazing facade solutions." },
        // { id: 10, title: "Structural Glazing Facades", image: "../../../asset/slid/2.jpg", description: "Discover our structural glazing facade options." },
        // { id: 11, title: "Point Fixed Facades", image: "../../../asset/slid/2.jpg", description: "Explore our point fixed facade solutions." }
      ]
    },
    {
      id: 3,
      title: "Office Partitions",
      image: "../../../asset/services/Services/3.png",
      description: "Customizable office partitions provide flexible and efficient space management. They are ideal for creating private workspaces while maintaining an open, collaborative environment. Available in various materials and designs to suit different office aesthetics.",
      subcategories: [
        // { id: 12, title: "Glass Partitions", image: "../../../asset/slid/2.jpg", description: "Discover our glass partition options." },
        // { id: 13, title: "Aluminium Partitions", image: "../../../asset/slid/2.jpg", description: "Explore our aluminium partition solutions." }
      ]
    },
    {
      id: 4,
      title: "Glass, Aluminium & Iron Balustrades/Handrails",
      image: "../../../asset/services/Services/4.png",
      description: "These balustrades and handrails combine functionality with style. Available in glass, aluminium, and iron, they offer safety and support while enhancing the aesthetic of staircases, balconies, and decks.",
      subcategories: [
        // { id: 14, title: "Glass Balustrades", image: "../../../asset/slid/2.jpg", description: "Discover our glass balustrade options." },
        // { id: 15, title: "Aluminium Balustrades", image: "../../../asset/slid/2.jpg", description: "Explore our aluminium balustrade solutions." },
        // { id: 16, title: "Iron Balustrades/Handrails", image: "../../../asset/slid/2.jpg", description: "Discover our iron balustrade/handrail options." }
      ]
    },
    {
      id: 5,
      title: "Skylights",
      image: "../../../asset/services/Services/5.png",
      description: "Skylights are perfect for adding natural light to any space, creating a more open and airy atmosphere. They are designed for energy efficiency and can dramatically transform living spaces, reduce lighting costs, and improve mood.",
      subcategories: [
        // { id: 17, title: "Fixed Skylights", image: "../../../asset/slid/2.jpg", description: "Discover our fixed skylight options." },
        // { id: 18, title: "Operable Skylights", image: "../../../asset/slid/2.jpg", description: "Explore our operable skylight solutions." }
      ]
    },
    {
      id: 6,
      title: "Tempered Glass Canopies",
      image: "../../../asset/services/Services/6.png",
      description: "These canopies provide elegant and durable shelter. Made from toughened glass, they are perfect for entrances and outdoor areas, offering protection from the elements while maintaining a clear, unobstructed view.",
      subcategories: [
        // { id: 19, title: "Single Slope Canopies", image: "../../../asset/slid/2.jpg", description: "Discover our single slope canopy options." },
        // { id: 20, title: "Double Slope Canopies", image: "../../../asset/slid/2.jpg", description: "Explore our double slope canopy solutions." },
        // { id: 21, title: "Customized Canopies", image: "../../../asset/slid/2.jpg", description: "Discover our customized canopy options." }
      ]
    },
    {
      id: 7,
      title: "Shower Cubicles/Shower Panels/Shower Enclosures",
      image: "../../../asset/services/Services/7.png",
      description: "These solutions offer a modern and hygienic approach to bathroom design. They are easy to clean, maintain, and are available in various styles and sizes, providing a functional yet stylish showering experience.",
      subcategories: [
        // { id: 22, title: "Shower Cubicles", image:"../../../asset/slid/2.jpg", description: "Discover our shower cubicle options." },
        // { id: 23, title: "Shower Panels", image: "../../../asset/slid/2.jpg", description: "Explore our shower panel solutions." },
        // { id: 24, title: "Shower Enclosures", image: "../../../asset/slid/2.jpg", description: "Discover our shower enclosure options." }
      ]
    },
    {
      id: 8,
      title: "High Quality Aluminium Pantry Cupboards",
      image: "../../../asset/services/Services/8.png",
      description: "These pantry cupboards are designed for durability and elegance. Made from high-quality aluminium, they are resistant to rust and wear, offering a sleek and contemporary storage solution for any kitchen.",
      subcategories: [
        // { id: 25, title: "Single Door Pantry Cupboards", image: "../../../asset/slid/2.jpg", description: "Discover our single door pantry cupboard options." },
        // { id: 26, title: "Double Door Pantry Cupboards", image: "../../../asset/slid/2.jpg", description: "Explore our double door pantry cupboard solutions." }
      ]
    },
    {
      id: 9,
      title: "Tempered Glass Backsplashes",
      image: "../../../asset/services/Services/9.png",
      description: "A stylish and practical addition to any kitchen, these backsplashes are made from tempered glass, making them heat resistant and easy to clean. They add a modern touch and can be customized in various colors and designs.",
      subcategories: [
        // { id: 27, title: "Standard Glass Backsplashes", image: "../../../asset/slid/2.jpg", description: "Discover our standard glass backsplash options." },
        // { id: 28, title: "Customized Glass Backsplashes", image:"../../../asset/slid/2.jpg", description: "Explore our customized glass backsplash solutions." }
      ]
    },
    {
      id: 10,
      title: "ACP Cladding Facades",
      image: "../../../asset/services/Services/10.png",
      description: " Aluminum Composite Panel (ACP) cladding facades provide a modern and stylish exterior for buildings. They are lightweight, durable, and available in a range of colors and finishes, offering both aesthetic appeal and protection against the elements.",
      subcategories: [
        // { id: 29, title: "Exterior ACP Cladding", image: "../../../asset/slid/2.jpg", description: "Discover our exterior ACP cladding options." },
        // { id: 30, title: "Interior ACP Cladding", image: "../../../asset/slid/2.jpg", description: "Explore our interior ACP cladding solutions." }
      ]
    }
  ];
  
  return (
    <div className="services-container p-5 md:p-10">
      {mainCategories.map((category) => (
        <div key={category.id} className="category">
          <div className="category-header" onClick={() => toggleCategoryExpansion(category.id)}>
            <img src={category.image} alt={category.title} />
            <h2>{category.title}</h2>
            <p className="text-justify">{category.description}</p>
            {/* Add expand button here */}
            {/* {category.subcategories.length > 0 ? ( */}
              <button style={{ color: 'darkblue' }}>
                {expandedCategories.includes(category.id) ? 'Collapse' : 'Expand'}
              </button>
            {/* // ) : null} */}
          </div>
          {expandedCategories.includes(category.id) && (
  <div className="subcategories">
{expandedCategories.includes(category.id) && (
  <div className="subcategories">
    {category.subcategories.length > 0 ? (
      category.subcategories.map((subcategory) => (
        <div key={subcategory.id} className="subcategory">
          <img src={subcategory.image} alt={subcategory.title} />
          <h3>{subcategory.title}</h3>
          <p>{subcategory.description}</p>
          <div className="subcategory-hover-popup">
            <h3>{subcategory.title}</h3>
            <p>{subcategory.description}</p>
          </div>
        </div>
      ))
    ) : (
      <p style={{ color: 'darkblue', textAlign: 'center' }}>No subcategories found</p>
    )}
  </div>
)}
  </div>
)}
        </div>
      ))}
    </div>
  );
};


export default ServicesPage;
