import React from 'react';
import MainLayout from '../layouts/MainLayout';
import Services from '../components/home/project';
import CompanyOverview from '../components/home/BriefAbout';

const Home = () => {
 
  return (
    <MainLayout>
      <Services />
      <CompanyOverview />

    </MainLayout>
  );
};

export default Home;
