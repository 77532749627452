import React from 'react';

const VisionAndMission = () => {
  return (
    <div className="bg-gray-100 p-5 md:p-10">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 text-center">
        {/* Vision Section */}
        <div>
          <h2 className="text-2xl font-bold text-gray-800 mb-3">Our Vision</h2>
          <p className="text-gray-600">
          To Harmoniously Blend Creativity & Functionolaty Enriching spaces through innovative design solutions & superiorconstruction expertise.we are commited to enhancing lifestyle by creating inspiring environment that reflect individuality,quality Craftmanship,and client centric collaboration.
          </p>
        </div>

        {/* Mission Section */}
        <div>
          <h2 className="text-2xl font-bold text-gray-800 mb-3">Our Mission</h2>
          <p className="text-gray-600">
          Our vision redefine interior spaces, setting industry benchmark through unparelleld design innovation and construction excellence. We aim to be the foremost choice for client seeking distinctive ,sustainable & timeless designs fostering enduring relationships and leaving an indelible mark of beauty & functionality on every project
          </p>
        </div>
      </div>
    </div>
  );
};

export default VisionAndMission;
