import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,

    appendDots: dots => (
      <div style={{ position: "absolute", top: "50%", right: "10px" }}>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };

  return (
    <>
 <style>
    
        {`
          .slick-dots li button:before {
            color: white; /* or any color you want */
          }
        `}
 
      </style>

      {/* Logo - Only show when not scrolled */}
      {!isScrolled && (
        <div className="absolute top-3 left-3 md:top-5 md:left-6 z-30">
          <img src="../../../asset/light-logo.png" alt="Company Logo" className="h-16 md:h-28" />
        </div>
      )}

      {/* Header Content with Slider */}
      {/* <header className="relative w-full h-[600px] md:h-[600px] pb-8 mb-1"> */}
      <header className="relative w-full mb-2">
        <Slider {...settings}>
          {/* Slide 1 */}
          <div className="relative">
  <img src="../../../asset/slid/1.jpg" alt="Slide 1" className="w-full h-full object-cover" />
  <div className="absolute left-5 md:left-10 top-[35%] md:top-[35%] transform -translate-y-1/2 z-20 p-4">
    <h2 className="text-2xl md:text-4xl font-bold text-white" style={{ fontFamily: 'Lobster, cursive' }}>Welcome</h2>
    <h1 className="text-2xl md:text-4xl font-bold text-white" style={{ fontFamily: 'Lobster, cursive' }}>Ayubowan</h1>
    <p className="text-2xl md:text-3xl font-bold text-white">Advanced Aluminium Pvt Ltd</p>
  </div>
</div>

          {/* Slide 2 */}
  <div className="relative">
    <img src="../../../asset/slid/2.jpg" alt="Slide 2" className="w-full h-full object-cover" />
            <div className="absolute left-5 md:left-10 top-[35%] md:top-[35%] transform -translate-y-1/2 z-20 p-4">
              <h2 className="text-2xl md:text-4xl font-bold text-white" >Trust Us</h2>
              <h1 className="text-2xl md:text-3xl font-bold text-white" style={{ fontFamily: 'Lobster, cursive' }}>Contact with Innovation</h1>
              <p className="text-2xl md:text-2xl font-bold text-white">Aluminium Art Instant</p>
            </div>
        </div>
          {/* Slide 3 */}
          <div className="relative">
            <img src="../../../asset/slid/3.jpg" alt="Slide 3" className="w-full h-full object-cover" />
            <div className="absolute left-5 md:left-10 top-[35%] md:top-[35%] transform -translate-y-1/2 z-20 p-4">
              <h2 className="text-2xl md:text-4xl font-bold text-white">A Variety Awaits</h2>
              <h1 className="text-2xl md:text-2xl font-bold text-white">Aluminum Styles for You</h1>
              <p className="text-2xl md:text-2xl font-bold text-white">Advanced Aluminium Pvt Ltd</p>
            </div>
          </div>

          {/* Slide 4 */}
          <div className="relative">
            <img src="../../../asset/slid/4.jpg" alt="Slide 4" className="w-full h-full object-cover" />
            <div className="absolute left-5 md:left-10 top-[35%] md:top-[35%] transform -translate-y-1/2 z-20 p-4">
              <h2 className="text-2xl md:text-4xl font-bold text-white">A Variety Awaits</h2>
              <h1 className="text-2xl md:text-2xl font-bold text-white">Aluminum Styles for You</h1>
              <p className="text-2xl md:text-2xl font-bold text-white">Advanced Aluminium Pvt Ltd</p>
            </div>
          </div>


        </Slider>
      </header>
    </>
  );
};

export default Header;
